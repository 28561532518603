import axios from 'axios';
import { Message } from '../models/chat';

const baseURL =
  process.env.REACT_APP_API_URL ||
  'https://wh-dev-moh-kuw-va-na.azurewebsites.net';

const api = axios.create({
  baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export const fetchConversation = async (
  conversationId: string
): Promise<Message[]> => {
  const { data } = await api.get<Message[]>(`/api/convo/history`, {
    params: {
      conversationId,
    },
  });
  return data;
};

export const sendMessage = async (
  conversationId: string,
  textMessage: string
): Promise<void> => {
  try {
    const { data } = await api.post(
      `/api/convo/text?UserTrackedId=${conversationId}`,

      JSON.stringify(textMessage)
    );
    return data;
  } catch (error) {
    console.error('Error sending message:', error);
    return undefined;
  }
};

export const sendAudioBuffer = async (
  conversationId: string,
  audioBlob: Blob
): Promise<string> => {
  try {
    const formData = new FormData();
    formData.append('audio', audioBlob, 'recording.wav');

    const response = await fetch(
      `${baseURL}/api/convo/audio/stream/v2?UserTrackedId=${conversationId}&ShouldRemuxAudio=true`,
      {
        method: 'POST',
        body: formData,
      }
    );

    const blob = await response.blob();
    const url = URL.createObjectURL(blob);

    return url;
  } catch (error) {
    console.error('Error sending audio:', error);
    throw new Error('Error while sending audio');
  }
};
